<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí video (vimeo)</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="syncVideo"
      >
        <v-icon color="white" dark>mdi-cached</v-icon
        ><span style="color: white">Đồng bộ video</span>
      </v-btn>
      <template v-if="Object.keys(file_videos).length > 0">
        <v-row>
          <template v-for="(file, index) in file_videos">
            <v-col
              cols="3"
              :key="index"
              style=""
              class="cursor-pointer"
              @click="openDialogFile(file, index)"
            >
              <v-icon style="width: 10%" class="" x-large>mdi-folder</v-icon>
              <v-text-field
                style="width: 85%"
                class="ml-2 d-inline-block"
                :label="index"
                solo
                disabled
              ></v-text-field>
            </v-col>
          </template>
        </v-row>
      </template>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogFile" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <p class="headline">
                Danh sách video
                <span>(Nhấn vào title của video để thêm, sửa link phụ)</span>
              </p>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogFile = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="8" style="display: inline">
                  <template v-for="(video, i) in videos">
                    <div
                      :key="i"
                      class="item-file-video mb-1 p-2 d-flex cursor-pointer"
                      @click="openDialogVideo(video, i)"
                    >
                      <span
                        class="font-weight-bold text-subtitle-1 mt-2 ml-2 flex-grow-1"
                        >{{ video.name }}</span
                      >
                      <span
                        class="font-weight-bold text-subtitle-1 mt-2 text-right"
                        >{{ video.default_provider }}</span
                      >
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <!--          <v-card-actions>-->
          <!--            <v-spacer></v-spacer>-->
          <!--            <button class="btn text-uppercase mr-2" style="color:#f64e60" @click="dialogFile = false">Close</button>-->
          <!--          </v-card-actions>-->
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogVideo" max-width="1000px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Video</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogVideo = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex align-center">
                    <label class="text-h6 mb-7">Link phụ (youtube):</label>
                    <v-text-field
                      outlined
                      dense
                      class="ml-4"
                      v-model="alternative_url_input"
                    ></v-text-field>
                  </div>
                  <div class="d-flex align-center">
                    <label class="text-h6 mb-7">Trình phát mặc đinh:</label>
                    <v-autocomplete
                      v-model="default_provider_input"
                      :items="provider_items"
                      dense
                      outlined
                      class="ml-2"
                    />
                  </div>
                  <template v-if="default_provider_input === 'vimeo'">
                    <b-embed
                      type="iframe"
                      aspect="16by9"
                      :src="videos[indexVideo].media_path"
                    ></b-embed>
                  </template>
                  <template v-if="default_provider_input === 'muse'">
                    <b-embed
                      type="iframe"
                      aspect="16by9"
                      :src="
                        'https://muse.ai/embed/' +
                        videos[indexVideo].muse_video_id +
                        '?'
                      "
                    ></b-embed>
                  </template>
                  <template v-if="default_provider_input === 'youtube'">
                    <template v-if="alternative_url_input">
                      <b-embed
                        type="iframe"
                        aspect="16by9"
                        :src="
                          'https://www.youtube.com/embed/' +
                          convertUrlToIdYt(alternative_url_input)
                        "
                      >
                      </b-embed>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              class="font-weight-bold"
              text
              @click="btnAddAlternativeUrl"
              outlined
              >Save</v-btn
            >
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogVideo = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import { getIdFromUrl } from "vue-youtube";
export default {
  name: "VideoList",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      file_videos: {},
      dialogFile: false,
      videos: [],
      indexFile: null,
      indexVideo: null,
      dialogVideo: false,
      alternative_url_input: null,
      default_provider_input: null,
      video_id: null,
      provider_items: ["vimeo", "youtube", "muse"],
    };
  },
  created() {
    this.getAllVideo();
  },
  methods: {
    async getAllVideo() {
      let vm = this;
      try {
        vm.is_call_api = true;
        // vimeo
        let res = await ApiService.get("prep-app/element/videos");

        // muse
        // let res = await ApiService.get('prep-app/element/videos/provider-muse');
        if (res.status === 200) {
          vm.file_videos = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDialogFile(file, indexFile) {
      this.indexFile = indexFile;
      // this.videos = JSON.parse(JSON.stringify(file));
      this.videos = file;
      this.dialogFile = true;
    },
    openDialogVideo(video, indexVideo) {
      this.indexVideo = indexVideo;
      this.alternative_url_input = video.alternative_url;
      this.default_provider_input = video.default_provider;
      this.video_id = video.id;
      this.dialogVideo = true;
    },
    convertUrlToIdYt(url) {
      let id = getIdFromUrl(url);
      return id;
    },
    validateForm() {
      let flat = true;
      if (
        this.alternative_url_input == null ||
        this.alternative_url_input === ""
      ) {
        this.$toasted.error("URL bị trống !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (
        this.default_provider_input == null ||
        this.default_provider_input === ""
      ) {
        this.$toasted.error("Trình phát mặc định không được bỏ trống !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    async btnAddAlternativeUrl() {
      let validator = this.validateForm();
      if (!validator) {
        return;
      }

      let vm = this;
      let data = {
        element_id: this.video_id,
        alternative_url: this.alternative_url_input,
        default_provider: this.default_provider_input,
      };
      this.is_call_api = true;
      await ApiService.post("prep-app/element/alternative-url", data)
        .then(function (response) {
          if (response) {
            vm.$toasted.success("Thêm thành công !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.file_videos[vm.indexFile][vm.indexVideo].alternative_url =
              vm.alternative_url_input;
            vm.file_videos[vm.indexFile][vm.indexVideo].default_provider =
              vm.default_provider_input;
            vm.dialogVideo = false;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          if (error) {
            vm.is_call_api = false;
            console.log(error);
          }
        });
    },
    async syncVideo() {
      let vm = this;
      try {
        vm.is_call_api = true;
        // vimeo
        let res = await ApiService.get("prep-app/element/sync-video");

        if (res.status === 200) {
          this.$toasted.success("Đồng bộ thành công !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          vm.file_videos = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        console.log(error);
        vm.$toasted.error("Đồng bộ không thành công !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        vm.is_call_api = false;
      }
    },
  },
};
</script>

<style scoped>
.item-file-video {
  border: 1px solid #d1d3e0;
  background-color: #d1d3e0;
  min-height: 25px;
  border-radius: 5px;
}
</style>
